// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/primeicons/primeicons.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,html,main{margin:0px;height:100%}body,html,main,div,a,span{font-family:\"Source Sans Pro\",sans-serif}.main{height:calc(100vh - var(--toolbar-height));width:calc(100vw - var(--navbar-width));margin-left:var(--navbar-width);margin-top:calc(var(--toolbar-height) + 3px)}", "",{"version":3,"sources":["webpack://./src/style.scss"],"names":[],"mappings":"AAGA,eACI,UAAA,CACA,WAAA,CAGJ,0BACI,wCAAA,CAGJ,MACI,0CAAA,CACA,uCAAA,CACA,+BAAA,CACA,4CAAA","sourcesContent":["@import \"primeicons/primeicons.css\";\n\n\nbody,html,main{\n    margin:0px;\n    height:100%;\n}\n\nbody,html,main,div,a,span {\n    font-family: 'Source Sans Pro', sans-serif;\n}\n\n.main{\n    height: calc(100vh - var(--toolbar-height));\n    width: calc(100vw - var(--navbar-width));\n    margin-left: var(--navbar-width);\n    margin-top: calc(var(--toolbar-height) + 3px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
